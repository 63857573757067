import { notNull } from "@warrenio/utils/notNull";
import { isDeepEqual } from "remeda";
import type { IFormModel } from "../../utils/getField.tsx";
import { produce } from "../../utils/immer.ts";
import type { SelectedOs } from "./os/os.ts";
import type { VmSizePackage } from "./VmSize.types.ts";
import {
    clampToRangeValues,
    convertToSizeValue,
    filterActiveOsPackages,
    getDefaultPackageForOs,
    getIsSizeRestricted,
    getSizeComponentRanges,
    type ComponentRangeValues,
    type SizeValue,
    type VmSizeParams,
} from "./vmSizeSelectUtils.ts";

export interface SizeInputs {
    size: SizeValue;
    size_ranges: ComponentRangeValues;
}

/** Common base interface for forms that allow selecting a VM size (eg. virtual machines & services). */
export interface SizeViewModel extends IFormModel<SizeInputs> {
    readonly _allSizePackages: VmSizePackage[];
    readonly _sizeParams: VmSizeParams;
    readonly _os: SelectedOs;

    onSizeChange(newValue: SizeValue, forceRecalculate: boolean): void;
}

export function onSizeChange(this: SizeViewModel, newValue: SizeValue, forceRecalculate: boolean) {
    console.debug("onSizeChange, value:", newValue);

    let ranges;
    const oldSize = this.get("size");
    if (oldSize?.vcpu !== newValue.vcpu || forceRecalculate) {
        // Adjust ranges if CPU size changes
        ranges = getSizeComponentRanges(this._sizeParams, this._os, {
            cpuValue: newValue.vcpu,
        });

        console.debug("onSizeRangesChange, value:", ranges);
        this.set("size_ranges", ranges);
    } else {
        ranges = this.get("size_ranges")!;
    }

    // NB: We must clamp the RAM on every change, because the size Slider might queue multiple `onSizeChange` events
    // in one event loop cycle, and all of those will have the old/unclamped `ram` value, not the clamped one.
    const ram = clampToRangeValues(ranges.ram, newValue.ram);
    if (ram !== newValue.ram) {
        console.debug("Clamped RAM to range", ram);
        newValue = { ...newValue, ram };
    }

    this.set("size", newValue);
}

export function getDefaultSizeRange(this: SizeViewModel, os?: SelectedOs, snapshotSize?: number): ComponentRangeValues {
    const sizeComponentRanges = getSizeComponentRanges(this._sizeParams, os ?? this._os);
    return snapshotSize
        ? produce(sizeComponentRanges, (draft) => {
              draft.ssd = draft.ssd.filter((size) => size >= snapshotSize);
          })
        : sizeComponentRanges;
}

export function forceSizeForOs(this: SizeViewModel, os: SelectedOs) {
    const matchingSize: SizeValue = { ...notNull(this.get("size")), isCustom: false };
    const matchedPackageSize = filterActiveOsPackages(this._allSizePackages, os).find((p) =>
        isDeepEqual(convertToSizeValue(p, false), matchingSize),
    );
    const newSize =
        matchedPackageSize !== undefined &&
        !getIsSizeRestricted(matchedPackageSize, getSizeComponentRanges(this._sizeParams, os))
            ? convertToSizeValue(matchedPackageSize, false)
            : getDefaultSizeForOs.call(this, os);
    this.onSizeChange(newSize, true);
}

export function getDefaultSizeForOs(this: SizeViewModel, os: SelectedOs): SizeValue {
    const defaultPackage = getDefaultPackageForOs(this._sizeParams, this._allSizePackages, os);
    if (!defaultPackage) {
        if (import.meta.env.DEV) {
            throw new Error(`No default package found for OS ${JSON.stringify(os)}`);
        } else {
            // CHK/S: I think we should warn client, that config is incomplete/invalid, need to add default package for OS
            // CHK/A: ↓
            // TODO: Should we handle the case where there is no default package for the OS?
            return { disks: 1, vcpu: 1, ram: 1, isCustom: true };
        }
    }

    return convertToSizeValue(defaultPackage, false);
}
