import type { VmParameter } from "@warrenio/api-spec/spec.oats.gen";
import { clamp } from "@warrenio/utils/clamp";
import { mustGet } from "@warrenio/utils/collections/mustGet";
import { find, first, last, round } from "remeda";
import invariant from "tiny-invariant";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import type { VmPriceFields } from "../pricing/resourcePricing.ts";
import type { VmSizePackage } from "./VmSize.types.ts";
import { osIsWindows, type SelectedOs } from "./os/os.ts";
import { parametersByNameAtom } from "./vmParametersQuery.ts";

export interface SizeValue {
    disks: number;
    vcpu: number;
    ram: number;
    isCustom: boolean;
}

export interface ComponentRangeValues {
    cpu: number[];
    ram: number[];
    ssd: number[];
}

/** VM-relevant constraints (parameters) extracted from API.
 * @see {@link getSizeParams}
 */
export interface VmSizeParams {
    cpu: VmParameter;
    ram: VmParameter;
    ssd: VmParameter;
}

export type Range = [min: number, max: number];

export function getSizeParams(params: Map<string, VmParameter>): VmSizeParams {
    return {
        cpu: mustGet(params, "vcpu", "params"),
        ram: mustGet(params, "ram", "params"),
        ssd: mustGet(params, "disks", "params"),
    };
}

export function useSizeParams() {
    const params = useSuspenseQueryAtom(parametersByNameAtom);
    return getSizeParams(params);
}

/** Check if the selected {@link os} matches a specific {@link field} constraint of the VM {@link pkg | package}. */
export function matchesPackageOsField(
    pkg: VmSizePackage,
    os: SelectedOs,
    field: "os" | "default" | "disabled",
): boolean {
    const osArray = pkg[field];
    if (osArray !== undefined) {
        return osIsWindows(os) ? osArray.includes("win") : osArray.includes("other");
    }
    return false;
}

export function filterOsPackages(allPackages: VmSizePackage[], os: SelectedOs): VmSizePackage[] {
    return allPackages.filter((s) => matchesPackageOsField(s, os, "os"));
}

const DISK_PACKAGE_SIZE_DIVISORS = [8, 4, 2, 1];

export function getDiskPackages(os: SelectedOs, maxSsdSize: number): VmSizePackage[] {
    return DISK_PACKAGE_SIZE_DIVISORS.map(
        (divisor): VmSizePackage => ({
            default: [],
            disabled: [],
            notice: "",
            os: [osIsWindows(os) ? "win" : "other"],
            ram: 0,
            cpu: 0,
            ssd: round(maxSsdSize / divisor, -1),
        }),
    );
}

export function filterActiveOsPackages<T extends VmSizePackage>(packages: T[], os: SelectedOs): T[] {
    return packages
        .filter((pkg) => matchesPackageOsField(pkg, os, "os"))
        .filter((pkg) => !matchesPackageOsField(pkg, os, "disabled"));
}

export function getDefaultPackageForOs(
    params: VmSizeParams,
    packages: VmSizePackage[],
    os: SelectedOs,
): VmSizePackage | undefined {
    const activePackages = filterActiveOsPackages(packages, os);
    const osPackage = activePackages.find((pkg) => matchesPackageOsField(pkg, os, "default")) ?? first(activePackages);
    if (!osPackage) {
        return undefined;
    }

    const range = getSizeComponentRanges(params, os);
    return getIsSizeRestricted(osPackage, range)
        ? find(filterOsPackages(activePackages, os), (item) => !getIsSizeRestricted(item, range))
        : osPackage;
}

export function clampToRangeValues(range: number[], value: number): number {
    invariant(range.length > 0, "Range must have at least one value");
    return clamp(value, range[0], last(range)!);
}

export function convertToSizeValue(sizeItem: VmSizePackage, isCustom: boolean): SizeValue {
    return {
        vcpu: sizeItem.cpu,
        ram: sizeItem.ram,
        disks: sizeItem.ssd,
        isCustom,
    };
}

export function getIsSizeRestricted(pkg: VmSizePackage, ranges: ComponentRangeValues, diskOnly = false): boolean {
    const ratioRestricted = !diskOnly && getCpuBoundRamMinimum(ranges.ram, pkg.cpu) > pkg.ram;
    const cpuRestricted = !diskOnly && pkg.cpu < ranges.cpu[0];
    const ramRestricted = !diskOnly && pkg.ram < ranges.ram[0];
    const ssdRestricted = pkg.ssd < ranges.ssd[0];
    return ratioRestricted || cpuRestricted || ramRestricted || ssdRestricted;
}

function getRangeInitialLimits(
    params: VmSizeParams,
    sizeComponentKey: keyof ComponentRangeValues,
    os: SelectedOs,
): Range {
    const sizeComponent = params[sizeComponentKey];
    const osLimit = (sizeComponent.limits ?? []).find((l) => l.os_name === os.os_name);
    const rangeMin = osLimit?.min ?? sizeComponent.min!;
    const rangeMax = osLimit?.max ?? sizeComponent.max!;
    return [rangeMin, rangeMax];
}

function getCpuBoundRamMinimum(ramValues: number[], cpuValue: number) {
    const initialMin = (cpuValue / 2) * 1024;

    if (ramValues.includes(initialMin)) return initialMin;

    const nextLargerValueInRange = ramValues.find((i) => i > initialMin);
    if (nextLargerValueInRange) return nextLargerValueInRange;

    const nextSmallerValueInRange = ramValues.find((i) => i < initialMin);
    if (nextSmallerValueInRange) return nextSmallerValueInRange;

    return ramValues[0];
}

function getCpuBoundRamMaximum(ramValues: number[], cpuValue: number) {
    const initialMax = cpuValue * 8 * 1024;

    const rangesMax = last(ramValues)!;
    if (initialMax < rangesMax) {
        if (ramValues.includes(initialMax)) return initialMax;

        const nextSmallerValueInRange = ramValues.find((i) => i < initialMax);
        if (nextSmallerValueInRange) return nextSmallerValueInRange;
    }

    return rangesMax;
}

function generateCpuRange([min, max]: Range) {
    const arr = [];
    for (let value = min; value <= max; ) {
        arr.push(value);

        if (value < 2) {
            value += 1;
        } else {
            value += 2;
        }
    }
    return arr;
}

function generateRamMbRange([min, max]: Range) {
    const arr = [];
    for (let value = min; value <= max; ) {
        arr.push(value);

        if (value < 2048) {
            value *= 2;
        } else {
            value += 2048;
        }
    }
    return arr;
}

function generateSsdGbRange([min, max]: [number, number], step = 10) {
    const arr = min < step ? [min] : [];

    const start = min < step ? step : min;
    for (let value = start; value <= max; ) {
        arr.push(value);

        value += step;
    }

    return arr;
}

interface ExtraSizeConstraints {
    cpuValue?: number;
    minDiskSize?: number;
}

export function getSizeComponentRanges(
    params: VmSizeParams,
    os: SelectedOs,
    constraints?: ExtraSizeConstraints,
): ComponentRangeValues {
    let ramRange = getRangeInitialLimits(params, "ram", os);

    const cpuValue = constraints?.cpuValue;
    if (cpuValue) {
        const ramRangeValues = generateRamMbRange(ramRange);
        ramRange = [getCpuBoundRamMinimum(ramRangeValues, cpuValue), getCpuBoundRamMaximum(ramRangeValues, cpuValue)];
    }

    return {
        cpu: generateCpuRange(getRangeInitialLimits(params, "cpu", os)),
        ram: generateRamMbRange(ramRange),
        ssd: generateSsdGbRange(getRangeInitialLimits(params, "ssd", os)).filter(
            (i) => i >= (constraints?.minDiskSize ?? 0),
        ),
    };
}

export function sizeToVmPriceFields(size: SizeValue, isDiskPrimary: boolean) {
    return {
        vcpu: size.vcpu,
        memory: size.ram,
        storage: [{ size: size.disks, primary: isDiskPrimary }],
        status: "running",
    } satisfies Partial<VmPriceFields>;
}

//#region Debugging
export function sizeRangesToString(ranges: ComponentRangeValues) {
    function rangeToS(range: number[]) {
        return `${range[0]}-${last(range)}`;
    }

    return `CPU: ${rangeToS(ranges.cpu)}, RAM: ${rangeToS(ranges.ram)}, SSD: ${rangeToS(ranges.ssd)}`;
}
//#endregion
